
export default {
  data() {
    return {
      alert: false,
      simpan1: false,
      model :'',
      inputSelect : [
        {id : '1', nama : 'kiken', alamat : 'beringin'},
        {id : '2',nama : 'Alung', alamat : 'Anawai'},
      ],
      date : '',
    }
  },
  methods: {
    simulateProgress (number) {
      // we set loading state
      this[`simpan${number}`] = true
      // simulate a delay
      setTimeout(() => {
        // we're done, we reset loading state
        this[`simpan${number}`] = false
      }, 3000)
    },

    KehadiranPie : function(){
      Highcharts.chart('KehadiranPie', {
      chart: {
          type: 'pie',
          backgroundColor: 'transparent'
      },
      title: {
          text: 'DATA PRESENSI KEHADIRAN'
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: false
          },
          showInLegend: true
      }
      },
      series: [{
          name: 'Persentase',
          colorByPoint: true,
          data: [
            {
              name: 'Hadir',
              y: 52.08,
              color:'#1e88e5',
              // sliced: true,
              // selected: true
            }, 
            {
              name: 'Izin',
              y: 22.92,
              color:'#7cb342',
            },
            {
              name: 'Tanpa Keterangan',
              y: 25.00,
              color:'#ff8a65',
            },
          
          ]
        }]
      });



    },

    KehadiranApelPie : function(){
      

      Highcharts.chart('KehadiranApelPie', {
      chart: {
          type: 'pie',
          backgroundColor: 'transparent',
          options3d: {
              enabled: true,
              alpha: 45,
              beta: 0
          }
      },
      title: {
          text: 'DATA PRESENSI APEL'
      },
      accessibility: {
          point: {
              valueSuffix: '%'
          }
      },
      tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
      },
      plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              depth: 35,
              dataLabels: {
                  enabled: true,
                  format: '{point.name}'
              }
          }
          },
          series: [{
              type: 'pie',
              name: 'Persentase',
              data: [
                  {
                    name: 'Hadir',
                    y: 52.08,
                    color:'#1e88e5',
                    // sliced: true,
                    // selected: true
                  }, 
                  {
                    name: 'Izin',
                    y: 22.92,
                    color:'#7cb342',
                  },
                  {
                    name: 'Tanpa Keterangan',
                    y: 25.00,
                    color:'#ff8a65',
                  },
              ]
          }]
      });









    },



    

    async button(){
      await setTimeout(() => {
        console.log('ini query pembacaannya')
      }, 2000);
      console.log('telASO')
    }







  },

  mounted () {
    this.KehadiranPie();
    this.KehadiranApelPie();
    this.button();
  },
}
