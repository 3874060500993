


import { AppFullscreen } from 'quasar'
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'LayoutDefault',

  components: {
    HelloWorld
  },

  data () {
    return {
      leftDrawerOpen: false,
      text:'',
      dense : true,
    }
  },
  methods: {

  },
  computed: {
      checkLogin() {
          if (this.$route.name === 'login') {
              return true;
          } else {
              return false;
          }
      }
  },

  mounted () {
    var get_profile = JSON.parse(localStorage.profile);

    // console.log(get_profile.profile)

    this.$store.state.unit_kerja = get_profile.profile.unit_kerja
    this.$store.state.unit_kerja_nama = get_profile.profile.unit_kerja_nama

  },
}
